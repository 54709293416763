@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /* stylelint-disable declaration-block-no-shorthand-property-overrides */
  font-size: 100%;
  font: inherit;
  /* stylelint-enable declaration-block-no-shorthand-property-overrides */
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: " ";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--magic-background, #fbfbfb);
  font: normal normal var(--magic-font-size, 14px) var(--magic-font-family, proxima-nova, Helvetica, Arial, sans-serif);
  font-weight: var(--magic-font-weight, 400);
  color: var(--magic-text, #747474);
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
      -ms-text-size-adjust: none;
          text-size-adjust: none;
  overflow-x: hidden;
}
body:-webkit-full-screen {
  width: 100%;
}
body.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}
*::after, *::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0 0 0.5em;
  font-family: var(--magic-header-font-family, proxima-nova, Helvetica, Arial, sans-serif);
  font-weight: var(--magic-header-font-weight, 700);
  color: inherit;
  line-height: 1;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 22px;
}

h3,
h4 {
  font-size: 18px;
}

p {
  margin: 0 0 1em;
  font-size: 12px;
}

strong,
b {
  font-weight: var(--magic-caption-font-weight, 600);
}

em,
i {
  font-style: italic;
}

a {
  outline: none;
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
button:hover, button:focus, button:active {
  outline: none;
}

input,
textarea,
button,
select {
  font-family: var(--magic-font-family, proxima-nova, Helvetica, Arial, sans-serif);
  font-size: 100%;
  color: inherit;
  line-height: 1;
}

input[type=date]::-webkit-date-and-time-value {
  margin-top: 6px;
}

input[type=date]::-webkit-clear-button,
input[type=date]::-webkit-inner-spin-button {
  margin: 0;
  opacity: 0;
}

input[type=date]::-webkit-calendar-picker-indicator {
  margin-right: 30px;
  width: 12px;
  height: 12px;
  color: rgba(var(--magic-light, #fff), 1);
}
input[type=date]::-webkit-calendar-picker-indicator:hover {
  background-color: rgba(var(--magic-light, #fff), 0);
}
input[type=date]::-webkit-calendar-picker-indicator:active {
  color: rgba(var(--magic-light, #fff), 1);
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-credentials-auto-fill-button {
  background: transparent;
}

::-webkit-contacts-auto-fill-button {
  background: transparent;
}

::-webkit-caps-lock-indicator {
  content: none;
}

video {
  background-color: rgba(var(--magic-dark, #000), 0);
}

div:focus,
label:focus {
  outline: none;
}

label {
  cursor: inherit;
}

@-moz-document url-prefix() {
  img {
    color: rgba(var(--magic-light, #fff), 0);
  }
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(var(--magic-dark, #000), 0);
}

div div[role=presentation] iframe {
  animation: none !important;
  transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
}
div div[role=presentation] div {
  transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
}
@media (max-height: 540px) and (max-width: 920px) and (orientation: landscape) {
  div div[role=presentation] iframe {
    transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
  }
  div div[role=presentation] div {
    transform: translate3d(0, var(--magic-bottom-navigation-height, 64px), 0);
  }
}
@media (max-height: 920px) and (max-width: 540px) and (orientation: portrait) {
  div div[role=presentation] iframe {
    transform: translate3d(0, calc(var(--magic-bottom-navigation-height, 64px) * -1), 0);
  }
  div div[role=presentation] div {
    transform: translate3d(0, var(--magic-bottom-navigation-height, 64px), 0);
  }
}
@media (min-width: 1024px) {
  div div[role=presentation] iframe {
    transform: none;
  }
  div div[role=presentation] div {
    transform: none;
  }
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spin-animation {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@keyframes scale-animation {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.woff2") format("woff2"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.woff") format("woff"), url("https://s3.eu-north-1.amazonaws.com/dunder-static.gigmagic.io/fonts/Brandon-black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}
html {
  --magic-primary: #00eaab;
  --magic-secondary: #292d39;
  --magic-tertiary: #231f20;
  --magic-background: #fbfbfb;
  --magic-text: #292d39;
  --magic-text-primary: #292d39;
  --magic-caption: #292d39;
  --magic-primary-contrast: var(--magic-light, #fff);
  --magic-secondary-contrast: var(--magic-light, #fff);
  --magic-success: #009b6e;
  --magic-error: #ff2334;
  --magic-alert: #ff9f32;
  --magic-alert-contrast: var(--magic-light, #fff);
  --magic-light-contrast: #231f20;
  --magic-field-border: #c6c6c6;
  --magic-header: #231f20;
  --magic-badge-background: #00eaab;
  --magic-medium: #292d39;
  --magic-button-primary-darker: #04dba0;
  --magic-button-medium-darker: #262a35;
  --magic-button-light-darker: #efefef;
  --magic-button-success-darker: #039167;
  --magic-button-error-darker: #ef2131;
  --magic-button-alert-darker: #f0952f;
  --magic-button-primary-transparent: rgba(0, 234, 171, 0.125);
  --magic-button-disabled-darker: #e4e4e4;
  --magic-button-alert-darker: #f0952f;
  --magic-button-disabled-transparent: #e4e4e4;
  --magic-button-light-transparent: transparent;
  --magic-checkbox-background: #00eaab;
  --magic-checkbox-border: #292d39;
  --magic-checkbox-hover-border: #00eaab;
  --magic-checkbox-focus-border: #00eaab;
  --magic-checkbox-checked-border: #00eaab;
  --magic-checkbox-disabled-border: #e4e4e4;
  --magic-checkbox-disabled-label: #e4e4e4;
  --magic-checkbox-disabled-background: #e4e4e4;
  --magic-radio-border: #231f20;
  --magic-radio-hover-border: #00eaab;
  --magic-radio-focus-border: #00eaab;
  --magic-radio-checked-border: #00eaab;
  --magic-radio-background: #00eaab;
  --magic-switch-checked-background: #00eaab;
  --magic-switch-hover-border: #00eaab;
  --magic-font-family: "Brandon Grotesque";
  --magic-header-font-family: "Brandon Grotesque";
  --magic-dropdown-padding-vertical: 12px;
  --magic-gradient-1: #00eaab linear-gradient(135deg, #00eaab, #241831);
  --magic-step-progress-bar-color: rgba(0, 234, 171, 0.3);
  --magic-account-menu-background: #212121;
  --magic-coin-balance-icon-color: #00eaab;
  --magic-shop-item-icon-color: #00eaab;
  --magic-shop-balance-icon-color: #00eaab;
  --magic-game-navigation-landscape-width: 98px;
  --magic-element-default-width: 344px;
  --magic-promotions-accordion-toggle-background: #231f20;
  --magic-offer-card-size: var(--magic-element-default-width, 288px);
}

html.color-theme-in-transition,
html.color-theme-in-transition *,
html.color-theme-in-transition *::before,
html.color-theme-in-transition *::after {
  transition: all 0.75s;
  transition-delay: 0;
}

html[data-theme=dark] {
  --magic-tertiary: black;
  --magic-tertiary-contrast: #b0b0b0;
  --magic-disabled: #8b8b8b;
  --magic-background: #1a1a1a;
  --magic-background-light: #343434;
  --magic-background-dark: #1a1a1a;
  --magic-text: #b0b0b0;
  --magic-caption: #c6c6c6;
  --magic-header: #c6c6c6;
  --magic-shadow: rgba(0, 0, 0, 0.75);
  --magic-shadow-light: rgba(0, 0, 0, 0.45);
  --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
  --magic-input-border: #4e4e4e;
  --magic-dropdown-border: #4e4e4e;
  --magic-selects-border: #4e4e4e;
  --magic-form-field-message-background: #252525;
  --magic-password-indicator-background: #252525;
}

@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) {
    --magic-tertiary: #009e73;
    --magic-tertiary-contrast: #b0b0b0;
    --magic-disabled: #8b8b8b;
    --magic-background: #1a1a1a;
    --magic-background-light: #343434;
    --magic-background-dark: #1a1a1a;
    --magic-text: #b0b0b0;
    --magic-caption: #c6c6c6;
    --magic-header: #c6c6c6;
    --magic-shadow: rgba(0, 0, 0, 0.75);
    --magic-shadow-light: rgba(0, 0, 0, 0.45);
    --magic-shadow-lighter: rgba(0, 0, 0, 0.25);
    --magic-input-border: #4e4e4e;
    --magic-dropdown-border: #4e4e4e;
    --magic-selects-border: #4e4e4e;
    --magic-form-field-message-background: #252525;
    --magic-password-indicator-background: #252525;
  }
}
.wallet--inner {
  padding: var(--magic-space, 16px);
}
@media (min-width: 423px) and (max-width: 440px) {
  .wallet--inner {
    padding: 0;
  }
}

.e-payment-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-enable no-duplicate-selectors */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-enable no-duplicate-selectors */
}
.e-payment-logo--admin::before {
  background: url("/img/icons/admin.svg");
}
.e-payment-logo--applepay::before {
  background: url("/img/icons/applepay.svg");
}
.e-payment-logo--astropaycard::before {
  background: url("/img/icons/astropaycard.svg");
}
.e-payment-logo--bank::before, .e-payment-logo--bank-st::before, .e-payment-logo--banktransfer::before, .e-payment-logo--banktransfer-st::before {
  background: url("/img/icons/banktransfer.svg");
}
:lang(fi) .e-payment-logo--bank::before, :lang(fi) .e-payment-logo--bank-st::before, :lang(fi) .e-payment-logo--banktransfer::before, :lang(fi) .e-payment-logo--banktransfer-st::before, html[data-user-country=FI] .e-payment-logo--bank::before, html[data-user-country=FI] .e-payment-logo--bank-st::before, html[data-user-country=FI] .e-payment-logo--banktransfer::before, html[data-user-country=FI] .e-payment-logo--banktransfer-st::before {
  background-image: url("/img/icons/fastbanktransfer-fi.svg");
}
:lang(sv) .e-payment-logo--bank::before, :lang(sv) .e-payment-logo--bank-st::before, :lang(sv) .e-payment-logo--banktransfer::before, :lang(sv) .e-payment-logo--banktransfer-st::before, html[data-user-country=SE] .e-payment-logo--bank::before, html[data-user-country=SE] .e-payment-logo--bank-st::before, html[data-user-country=SE] .e-payment-logo--banktransfer::before, html[data-user-country=SE] .e-payment-logo--banktransfer-st::before {
  background-image: url("/img/icons/fastbanktransfer-sv.svg");
}
.e-payment-logo--creditcard::before, .e-payment-logo--credit-card::before {
  background: url("/img/icons/creditcard.svg");
}
:lang(no) .e-payment-logo--creditcard::before, :lang(no) .e-payment-logo--credit-card::before, :lang(no) .e-payment-logo--visa::before, :lang(no) .e-payment-logo--visadebit::before, :lang(no) .e-payment-logo--visaelectron::before, html[data-user-country=NO] .e-payment-logo--creditcard::before, html[data-user-country=NO] .e-payment-logo--credit-card::before, html[data-user-country=NO] .e-payment-logo--visa::before, html[data-user-country=NO] .e-payment-logo--visadebit::before, html[data-user-country=NO] .e-payment-logo--visaelectron::before {
  background-image: url("/img/icons/visa.svg");
}
.e-payment-logo--cryptocurrency::before {
  background: url("/img/icons/cryptopay.svg");
}
.e-payment-logo--epro::before {
  background: url("/img/icons/epro.svg");
}
:lang(no) .e-payment-logo--epro::before, html[data-user-country=NO] .e-payment-logo--epro::before {
  background-image: url("/img/icons/epro-no.svg");
}
.e-payment-logo--euteller::before {
  background: url("/img/icons/euteller.svg");
}
.e-payment-logo--funanga::before {
  background: url("/img/icons/cashtocodeevoucher.svg");
}
.e-payment-logo--ideal::before, .e-payment-logo--envoy_ideal::before {
  background: url("/img/icons/ideal.svg");
}
.e-payment-logo--instadebit::before, .e-payment-logo--envoy_instadebit::before {
  background: url("/img/icons/poli.svg");
}
.e-payment-logo--instadebitecheck::before {
  background: url("/img/icons/instadebit.svg");
}
.e-payment-logo--jeton::before {
  background: url("/img/icons/jeton.svg");
}
.e-payment-logo--jetongo::before, .e-payment-logo--jetgo::before {
  background: url("/img/icons/jetongo.svg");
}
.e-payment-logo--maestro::before {
  background: url("/img/icons/maestro.svg");
}
.e-payment-logo--mastercard::before {
  background: url("/img/icons/mastercard.svg");
}
.e-payment-logo--neosurf::before, .e-payment-logo--neosurfvoucher::before {
  background: url("/img/icons/neosurf.svg");
}
.e-payment-logo--neteller::before {
  background: url("/img/icons/neteller.svg");
}
.e-payment-logo--paysafecard::before {
  background: url("/img/icons/paysafecard.svg");
}
.e-payment-logo--poli::before, .e-payment-logo--envoy_poli::before {
  background: url("/img/icons/poli.svg");
}
.e-payment-logo--pugglepay::before {
  background: url("/img/icons/zimpler.svg");
}
.e-payment-logo--skrill::before {
  background: url("/img/icons/skrill.svg");
}
.e-payment-logo--skrill1tap::before, .e-payment-logo--skrill_1tap::before {
  background: url("/img/icons/skrill1tap.svg");
}
.e-payment-logo--sofort::before, .e-payment-logo--envoy_sofort::before {
  background: url("/img/icons/sofort.svg");
}
:lang(sv) .e-payment-logo--sofort::before, html[data-user-country=SE] .e-payment-logo--sofort::before {
  background-image: url("/img/icons/klarna.svg");
}
.e-payment-logo--webredirect_sticpay::before {
  background: url("/img/icons/sticpay.svg");
}
.e-payment-logo--sticpay::before {
  background: url("/img/icons/sticpay.svg");
}
.e-payment-logo--trustly::before {
  background: url("/img/icons/trustly.svg");
}
.e-payment-logo--visa::before {
  background: url("/img/icons/visa.svg");
}
.e-payment-logo--visadebit::before, .e-payment-logo--visa_debit::before {
  background: url("/img/icons/visadebit.svg");
}
.e-payment-logo--visaelectron::before, .e-payment-logo--visa_electron::before {
  background: url("/img/icons/visaelectron.svg");
}
.e-payment-logo--zimpler::before {
  background: url("/img/icons/zimpler.svg");
}
.e-payment-logo--seqr::before {
  background: url("/img/icons/seqr.svg");
}
.e-payment-logo--siru::before {
  background: url("/img/icons/sirumobile.svg");
}
.e-payment-logo--kluwp::before {
  background: url("/img/icons/evoucher.svg");
}
.e-payment-logo--paypal::before {
  background: url("/img/icons/paypal.svg");
}
.e-payment-logo--muchbetter::before {
  background: url("/img/icons/muchbetter.svg");
}
.e-payment-logo--interac::before {
  background: url("/img/icons/interac.svg");
}
.e-payment-logo--e_transfer::before {
  background: url("/img/icons/interacetransfer.svg");
}
.e-payment-logo--skrillqco::before {
  background: url("/img/icons/rapidtransfer.svg");
}
.e-payment-logo--swish::before {
  background: url("/img/icons/swish.svg");
}
.e-payment-logo--venuspoints::before, .e-payment-logo--venuspoint::before {
  background: url("/img/icons/venuspoints.svg");
}
.e-payment-logo--ecopayz::before {
  background: url("/img/icons/ecopayz.svg");
}
.e-payment-logo--inpay::before {
  background: url("/img/icons/inpay.svg");
}
.e-payment-logo--seb::before {
  background: url("/img/icons/seb.svg");
}
.e-payment-logo--swedbank::before {
  background: url("/img/icons/swedbank.svg");
}
.e-payment-logo--webredirect::before {
  background: url("/img/icons/creditcard.svg");
}
.e-payment-logo--giropay::before {
  background: url("/img/icons/giropay.svg");
}
.e-payment-logo--totalcoincard::before {
  background: url("/img/icons/totalcoincard.svg");
}
.e-payment-logo::before {
  content: " ";
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
}

.p-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.p-actions__button {
  flex: 0 0 auto;
  margin: var(--magic-space--small, 8px);
  width: auto;
}
@media (min-width: 500px) {
  .p-actions__button {
    width: auto;
  }
}

.p-bonus {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0 var(--magic-space, 16px);
  border-radius: var(--magic-border-radius, 4px);
  width: 100%;
  min-height: var(--magic-bonus-size, 80px);
  background-color: var(--magic-light, #fff);
  background-image: url("/img/icons/wallet-bonus.svg");
  background-repeat: no-repeat;
  background-position: 20px 20px;
  background-size: 40px 40px;
  box-shadow: 0 0 10px 0 var(--magic-bonus-dark-transparent, rgba(0, 0, 0, 0.125));
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  cursor: pointer;
}
.p-bonus:hover, .p-bonus:focus {
  opacity: 0.85;
}
.p-bonus--cancel {
  background-image: url("/img/icons/wallet-no-bonus.svg");
}
.p-bonus--changer {
  margin: 0;
  border: none;
  width: var(--magic-space--large, 32px);
  height: var(--magic-space--large, 32px);
  min-height: 0;
  background-color: transparent;
  background-position: 0 0;
  background-size: var(--magic-space--large, 32px) var(--magic-space--large, 32px);
  box-shadow: none;
}
.p-bonus--changer:hover, .p-bonus--changer:focus {
  opacity: 1;
}
.p-bonus--changer .p-bonus__header {
  position: relative;
  justify-content: flex-end;
  padding: 0;
  height: 0;
}
.p-bonus--changer .p-bonus__description {
  font-size: 0.69rem;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 var(--magic-space--small, 8px) 0 0;
  max-height: none;
  font-weight: normal;
  text-align: right;
  text-transform: uppercase;
  line-height: calc(var(--magic-bonus-image-size, 26px) / 2);
  white-space: nowrap;
  transform: translate3d(-100%, 0, 0);
}
.p-bonus--changer .p-bonus__description b {
  font-size: 0.75rem;
  font-weight: var(--magic-font-weight-bold, 700);
}
.p-bonus.is-floating {
  position: absolute;
  top: calc(var(--magic-bonus-image-size, 26px) + var(--magic-space, 16px));
  left: 0;
  opacity: 0;
  pointer-events: none;
  cursor: auto;
  z-index: 1;
}
.p-bonus.is-floating.is-open {
  opacity: 1;
  pointer-events: all;
}
.p-bonus.is-floating .p-bonus__header {
  cursor: default;
}
.p-bonus.is-floating .p-bonus__information {
  margin: var(--magic-space--x-small, 4px) 0;
  font-weight: normal;
  text-transform: none;
}
.p-bonus.is-floating .p-bonus__toggle:checked ~ .p-bonus__button--icon {
  background: url("/img/icons/wallet-bonus-close.svg");
}
.p-bonus.is-floating .p-bonus__toggle:checked ~ .p-bonus__button--text {
  background: none;
}
.p-bonus.is-floating .p-bonus__button--icon {
  background: url("/img/icons/wallet-bonus-close.svg");
  margin: calc(var(--magic-space--small, 8px) + var(--magic-space--x-small, 4px));
  top: -6px;
  right: -6px;
}
.p-bonus.is-floating .p-bonus__button--icon:hover, .p-bonus.is-floating .p-bonus__button--icon:focus {
  opacity: 0.65;
}
.p-bonus.is-floating .p-bonus__button--text {
  background: none;
  font-weight: var(--magic-font-weight-semibold, 600);
  position: relative;
  top: auto;
  right: auto;
  margin: 0 auto calc(var(--magic-space--small, 8px) + var(--magic-space--x-small, 4px));
  width: auto;
  text-decoration: none;
  color: var(--magic-text-primary, #0c66ff);
}
.p-bonus.is-floating .p-bonus__button--text:hover, .p-bonus.is-floating .p-bonus__button--text:focus {
  text-decoration: underline;
}
.p-bonus.is-open .p-bonus__description {
  height: auto;
  max-height: 500px;
}
.p-bonus__header {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  padding: 0 0 0 calc(var(--magic-space--x-large, 64px) + var(--magic-space, 16px));
  width: 100%;
  height: var(--magic-bonus-size, 80px);
  cursor: pointer;
}
.p-bonus__image {
  display: none;
}
.p-bonus__title {
  font-size: 0.69rem;
  font-weight: var(--magic-font-weight-bold, 700);
  margin: 0;
  text-transform: uppercase;
  color: var(--magic-header, #161616);
}
.p-bonus--cancel .p-bonus__title {
  text-transform: none;
}
.p-bonus__description {
  display: block;
  margin: 0;
  height: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--magic-duration, 0.3s) ease;
  will-change: max-height;
}
.p-bonus__text {
  font-size: 0.69rem;
  font-weight: var(--magic-font-weight-bold, 700);
  margin: 0;
  padding: 0 var(--magic-space, 16px) var(--magic-space--small, 8px);
  text-align: center;
}
.p-bonus__text span {
  display: inline-block;
  margin: 0 0 var(--magic-space--x-small, 4px);
}
.p-bonus__button {
  background: url("/img/icons/wallet-bonus-info.svg");
  position: absolute;
  top: var(--magic-space--small, 8px);
  right: var(--magic-space--small, 8px);
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  z-index: 0;
  cursor: pointer;
}
.p-bonus__toggle {
  position: absolute;
  top: var(--magic-space--small, 8px);
  right: var(--magic-space--small, 8px);
  margin: 0;
  padding: 0;
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
  opacity: 0;
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  z-index: 1;
  cursor: pointer;
}
.p-bonus__toggle:hover ~ .p-bonus__button, .p-bonus__toggle:focus ~ .p-bonus__button {
  opacity: 0.65;
}
.p-bonus__toggle:checked ~ .p-bonus__description {
  height: auto;
  max-height: 500px;
}
.p-bonus__toggle:checked ~ .p-bonus__button {
  background: url("/img/icons/wallet-bonus-collapse.svg");
}

.p-bonuses__list {
  position: relative;
  margin: var(--magic-space, 16px) 0 0 0;
  min-width: var(--magic-wallet-mobile-size, 290px);
}

.p-button {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  display: inline-block;
  flex: 0 0 auto;
  position: relative;
  margin: 0 0 var(--magic-space, 16px);
  padding: calc(var(--magic-space, 16px) - calc(var(--magic-space--x-small, 4px) / 2)) var(--magic-space--small, 8px);
  border: none;
  border-radius: var(--magic-button-border-radius, 4px);
  outline: none;
  width: auto;
  background-color: var(--magic-medium, #747474);
  text-align: center;
  text-decoration: none;
  color: var(--magic-medium-contrast, #fff);
  line-height: 20px;
  transform: scale(1);
  transition: transform calc(var(--magic-duration, 0.3s) / 2) ease, background-color var(--magic-duration, 0.3s) ease;
  will-change: transform, background-color;
  -webkit-appearance: none;
}
.p-button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.p-button:hover, .p-button:focus {
  background-color: var(--magic-button-medium-darker, #646464);
}
.p-button:active {
  transform: scale(0.95);
}
.p-button--primary {
  width: 100%;
  background-color: var(--magic-primary, #0c66ff);
  color: var(--magic-primary-contrast, #fff);
}
.p-button--primary:hover, .p-button--primary:focus {
  background-color: var(--magic-button-primary-darker, #00ca94);
}
.p-button--primary.is-inactive {
  background-color: var(--magic-disabled, #e4e4e4);
  color: var(--magic-button-disabled-darker, #b4b4b4);
  pointer-events: none;
}
.p-button--primary.is-inactive:hover, .p-button--primary.is-inactive:focus {
  background-color: var(--magic-disabled, #e4e4e4);
  color: var(--magic-button-disabled-darker, #b4b4b4);
}
.p-button--primary.is-inactive:active {
  transform: none;
}
.p-button--back {
  display: none;
}
.p-button--bonus {
  margin: 0;
}
.p-button--amount {
  flex: 1 0 auto;
  margin: 0 var(--magic-space--xx-small, 2px);
  padding: var(--magic-space--small, 8px);
  letter-spacing: -0.02em;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px var(--magic-primary, #0c66ff);
  color: var(--magic-text-primary, #0c66ff);
}
.p-button--amount:hover {
  background-color: var(--magic-button-primary-transparent, rgba(0, 234, 171, 0.125));
}
.p-button--amount:focus, .p-button--amount:active {
  background-color: var(--magic-primary, #0c66ff);
  color: var(--magic-primary-contrast, #fff);
}
.p-button--amount:first-child {
  margin: 0 2px 0 0;
}
.p-button--amount:last-child {
  margin: 0 0 0 var(--magic-space--xx-small, 2px);
}
.p-button--cancel {
  width: 100%;
  background-color: transparent;
  color: var(--magic-text-primary, #0c66ff);
}
.p-button--cancel:hover {
  background-color: var(--magic-button-primary-transparent, rgba(0, 234, 171, 0.125));
}
.p-button--cancel:focus, .p-button--cancel:active {
  background-color: var(--magic-primary, #0c66ff);
  color: var(--magic-primary-contrast, #fff);
}
.p-button--empty {
  top: var(--magic-space--xx-small, 2px);
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
}
.p-button--empty:hover, .p-button--empty:focus {
  background-color: transparent;
  opacity: 0.65;
}
@media (min-width: 500px) {
  .p-button--primary {
    width: 100%;
  }
  .p-button--amount {
    padding: var(--magic-space--small, 8px);
  }
}

.p-card-name:hover .p-card-name__button, .p-card-name:focus .p-card-name__button {
  opacity: 0.6;
}
.p-card-name--changer {
  display: flex;
  justify-content: center;
}
.p-card-name__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.p-card-name__description {
  margin: 0;
  line-height: 1;
}
.p-card-name__description--ellipsis {
  max-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.p-card-name__button {
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  cursor: pointer;
}
.p-card-name__button:hover, .p-card-name__button:focus {
  opacity: 0.6;
}
.p-card-name__button--icon {
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
}
.p-card-name__button--edit {
  background: url("/img/icons/wallet-edit.svg");
  margin: 0 0 0 var(--magic-space--small, 8px);
}

.p-bonus-page .p-content__footer:first-of-type {
  margin: 0 0 calc(var(--magic-space, 16px) + var(--magic-space--small, 8px));
}

.p-compliance {
  opacity: 0;
  will-change: opacity;
  animation: loader-fade-animation 0.6s 1s linear 1 forwards;
}
.p-compliance__header {
  font-size: 1rem;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  margin: 0 0 var(--magic-space, 16px);
  text-align: left;
  color: var(--magic-header, #161616);
}
.p-compliance__subheader {
  font-size: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 var(--magic-space--large, 32px);
  text-align: left;
}
.p-compliance__button {
  max-width: var(--magic-wallet-mobile-size, 290px);
}
@media (min-width: 1024px) {
  .p-compliance {
    width: 100%;
    max-width: var(--magic-wallet-desktop-size, 440px);
  }
}
@keyframes loader-fade-animation {
  to {
    opacity: 1;
  }
}

.p-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.p-content.inactive {
  pointer-events: none;
}
.p-content h5 {
  font-size: 0.75rem;
  margin: 0 0 var(--magic-space--small, 8px);
}
.p-content__section, .p-content__footer {
  position: relative;
  min-width: var(--magic-wallet-mobile-size, 290px);
  max-width: var(--magic-wallet-mobile-size, 290px);
}
.p-content__section--external {
  flex: 1 0 auto;
  align-self: stretch;
  margin: 0 auto;
  padding: 0 var(--magic-space--small, 8px);
  width: 100%;
  min-width: 0;
  max-width: none;
  text-align: center;
}
.p-content__section.has-bonus {
  margin-top: var(--magic-space, 16px);
}
.p-content__footer {
  margin: 0 auto;
}
@media (min-width: 500px) {
  .p-content {
    position: relative;
    margin: 0 auto;
    padding: var(--magic-space, 16px) 0;
    max-width: 38.75rem;
    min-height: 24.69rem;
  }
}
@media (min-width: 1024px) {
  .p-content__section--external {
    margin: 0;
    width: 100%;
  }
}

html,
body {
  height: auto;
  overflow: auto;
}

body.is-iframe {
  background: none;
}

bdi {
  white-space: nowrap;
}

.p-field {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0 var(--magic-space, 16px);
  width: auto;
}
.p-field--buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.p-field--amount {
  width: 100%;
}
.p-fields--quick-deposit .p-field--amount {
  width: 100%;
}
.p-field--card {
  padding: 0;
  width: 100%;
}
.p-field--withdraw-message {
  margin: var(--magic-space, 16px) 0 calc(var(--magic-space--small, 8px) * -1);
}
.p-field--card-name {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  flex: 1 0 auto;
}
.p-field--card-number {
  flex: 1 0 auto;
}
.p-field--card-expiry {
  margin: 0;
  width: calc(50% - (8px - var(--magic-space--xx-small, 2px)));
}
.p-fields--quick-deposit .p-field--card-expiry {
  margin: 0;
  width: calc(50% - (8px - var(--magic-space--xx-small, 2px)));
}
.p-field--card-cvc .p-field__button--icon, .p-field--withdraw-bank .p-field__button--icon {
  background: url("/img/icons/wallet-info.svg");
  display: block;
  position: absolute;
  top: 19px;
  right: 0;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 50% 50%;
  opacity: 1;
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
}
.p-field--card-cvc .p-field__button--icon:hover, .p-field--card-cvc .p-field__button--icon:focus, .p-field--withdraw-bank .p-field__button--icon:hover, .p-field--withdraw-bank .p-field__button--icon:focus {
  opacity: 0.6;
}
html[data-theme=dark] .p-field--card-cvc .p-field__button--icon, html[data-theme=dark] .p-field--withdraw-bank .p-field__button--icon {
  background: url("/img/icons/wallet-info-dark.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 50% 50%;
}
@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) .p-field--card-cvc .p-field__button--icon, html:not([data-theme=light]) .p-field--withdraw-bank .p-field__button--icon {
    background: url("/img/icons/wallet-info-dark.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: 50% 50%;
  }
}
.p-field--card-cvc {
  margin: 0;
  width: calc(50% - (8px - var(--magic-space--xx-small, 2px)));
}
.p-fields--quick-deposit .p-field--card-cvc {
  margin: 0;
  width: calc(50% - (8px - var(--magic-space--xx-small, 2px)));
}
.p-readonly .p-field--history {
  opacity: 0.5;
  color: var(--magic-text, #747474);
}
.p-field__info {
  font-size: 0.75rem;
}
.p-field__info--amount, .p-field__info--card, .p-field__info--withdraw, .p-field__info--neteller, .p-field__info--history, .p-field__info--bonus {
  order: -3;
  margin: 0 0 var(--magic-space--xx-small, 2px);
}
.p-field__info--amount {
  display: flex;
  justify-content: space-between;
}
.p-field__info--card {
  white-space: nowrap;
}
.p-field__info--bonus {
  display: none;
}
.p-field__error {
  font-size: 0.75rem;
  display: block;
  position: relative;
  padding: 0;
  border-radius: var(--magic-border-radius, 4px);
  box-shadow: var(--magic-shadow-2, 0 3px 4px 0 var(--magic-shadow-light, rgba(10, 31, 68, 0.1)), 0 0 1px 0 var(--magic-shadow-lighter, rgba(10, 31, 68, 0.08)), 0 0 0 0.2px var(--magic-shadow-lighter, rgba(10, 31, 68, 0.08)));
  width: 100%;
  max-height: 0;
  opacity: 0;
}
.p-field__error--amount, .p-field__error--card, .p-field__error--withdraw, .p-field__error--neteller, .p-field__error--history, .p-field__error--bonus {
  color: var(--magic-text-error, #dd2727);
  transition: padding-top 0s ease, padding-bottom 0s ease, max-height 0s ease, opacity 0s ease;
  will-change: padding-top, padding-bottom, max-height, opacity;
  overflow: hidden;
}
.p-field.p-invalid.p-dirty .p-field__error--amount, .p-field.p-invalid.p-dirty.p-focus .p-field__error--amount, .p-field.p-invalid.p-dirty .p-field__error--card, .p-field.p-invalid.p-dirty.p-focus .p-field__error--card, .p-field.p-invalid.p-dirty .p-field__error--withdraw, .p-field.p-invalid.p-dirty.p-focus .p-field__error--withdraw, .p-field.p-invalid.p-dirty .p-field__error--neteller, .p-field.p-invalid.p-dirty.p-focus .p-field__error--neteller, .p-field.p-invalid.p-dirty .p-field__error--history, .p-field.p-invalid.p-dirty.p-focus .p-field__error--history, .p-field.p-invalid.p-dirty .p-field__error--bonus, .p-field.p-invalid.p-dirty.p-focus .p-field__error--bonus {
  padding: var(--magic-space--small, 8px) calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px));
  max-height: 50px;
  opacity: 1;
  transition: padding-top var(--magic-duration, 0.3s) ease, padding-bottom var(--magic-duration, 0.3s) ease, max-height var(--magic-duration, 0.3s) ease, opacity var(--magic-duration, 0.3s) ease;
}
.p-field__error--card-expiry {
  width: 100%;
}
.p-field__error--card-cvc {
  width: 100%;
}
.p-field__button {
  font-size: 0.75rem;
  padding: var(--magic-space--x-small, 4px) var(--magic-space--small, 8px);
  text-transform: none;
  white-space: nowrap;
  line-height: 1;
  transform: translate3d(0, 0, 0);
}
.p-field__button--icon {
  display: none;
}
.p-field__button--card-name {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
  text-decoration: underline;
}
.p-field__button--card-name:hover, .p-field__button--card-name:focus {
  text-decoration: none;
  opacity: 1;
}
.p-field.p-focus.p-dirty.p-empty .p-field__button {
  transform: translate3d(0, 0, 0);
}
.p-field.p-typing.p-focus.p-dirty .p-field__button {
  transform: translate3d(0, 0, 0);
}
.p-field.p-invalid.p-dirty .p-field__button {
  transform: translate3d(0, 0, 0);
}
.p-field.p-valid.p-dirty .p-field__button {
  transform: translate3d(0, 0, 0);
}
.p-field__icon {
  display: none;
}

.p-fields--card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}
.has-bonus .p-fields--bonus {
  display: none;
}

.p-form__footer {
  margin: var(--magic-space--large, 32px) 0 0;
  width: 100%;
}

.p-header {
  font-size: 1rem;
  margin: 0 0 var(--magic-space, 16px);
  text-align: center;
  color: var(--magic-header, #161616);
}
.p-header--withdraw {
  margin: 0 0 var(--magic-space, 16px) !important;
}
.p-header--withdraw .p-subheader__text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-input {
  background: var(--magic-background, #fbfbfb);
  font-size: 0.88rem;
  display: block;
  padding: var(--magic-input-padding-vertical, 14px) var(--magic-input-padding-horizontal, 16px);
  border: none;
  border-radius: var(--magic-border-radius, 4px);
  width: 100%;
  height: var(--magic-input-height, 44px);
  box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4));
  color: var(--magic-caption, #161616);
  line-height: var(--magic-input-line-height, var(--magic-space, 16px));
  transition: box-shadow var(--magic-duration, 0.3s) ease;
  will-change: box-shadow;
  -webkit-appearance: none;
}
.p-readonly .p-input {
  box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4));
  color: var(--magic-text-lighter, #c6c6c6);
}
.p-input:focus {
  box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff);
  outline: none;
}
.p-readonly .p-input:focus {
  box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4));
  color: var(--magic-text-lighter, #c6c6c6);
}
.p-input:-ms-input-placeholder {
  font-size: 0.88rem;
  color: var(--magic-text-lighter, #c6c6c6);
}
.p-input::-ms-input-placeholder {
  font-size: 0.88rem;
  color: var(--magic-text-lighter, #c6c6c6);
}
.p-input::placeholder {
  font-size: 0.88rem;
  color: var(--magic-text-lighter, #c6c6c6);
}
.p-input::-webkit-input-placeholder {
  font-size: 0.88rem;
  color: var(--magic-text-lighter, #c6c6c6);
  line-height: normal;
}
.p-input::-moz-placeholder {
  font-size: 0.88rem;
  color: var(--magic-text-lighter, #c6c6c6);
  opacity: 1;
}
.p-input::-ms-input-placeholder {
  font-size: 0.88rem;
  color: var(--magic-text-lighter, #c6c6c6);
}
.p-field.p-valid.p-dirty .p-input {
  box-shadow: inset 0 0 0 1px var(--magic-success, #22c993);
}
.p-field.p-invalid.p-dirty .p-input {
  box-shadow: inset 0 0 0 1px var(--magic-error, #f03d3d);
}
.p-field.p-valid.p-dirty.p-focus .p-input {
  box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff);
}
.p-field.p-invalid.p-dirty.p-focus .p-input {
  box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff);
}
.p-input:-webkit-autofill {
  font-size: 0.88rem;
  -webkit-box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4)), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
  -webkit-text-fill-color: var(--magic-text, #747474);
  -webkit-animation-name: autofill-animation;
  -webkit-animation-fill-mode: both;
  line-height: normal;
  -webkit-transition: background-color 9999s 0s linear;
  transition: background-color 9999s 0s linear;
  will-change: background-color;
}
.p-input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 1px var(--magic-field-border, var(--magic-input-border, #b4b4b4)), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
.p-input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
.p-field.p-valid.p-dirty .p-input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1px var(--magic-success, #22c993), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
.p-field.p-invalid.p-dirty .p-input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 1px var(--magic-error, #f03d3d), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
.p-field.p-valid.p-dirty.p-focus .p-input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
.p-field.p-invalid.p-dirty.p-focus .p-input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff), inset 0 0 0 9999px var(--magic-input-background, var(--magic-background, #fbfbfb));
}
@keyframes autofill-animation {
  to {
    background-color: var(--magic-input-background, var(--magic-background, #fbfbfb));
    color: var(--magic-text, #747474);
  }
}

.p-label {
  font-size: 0.88rem;
}
.p-label--amount {
  display: none;
}
.p-label--card, .p-label--withdraw, .p-label--neteller, .p-label--history, .p-label--bonus {
  position: absolute;
  top: calc(var(--magic-space--large, 32px) - (var(--magic-space--xx-small, 2px) / 2));
  left: var(--magic-space, 16px);
  color: var(--magic-text-lighter, #c6c6c6);
  transition: opacity calc(var(--magic-duration, 0.3s) / 2) ease;
  will-change: opacity;
  overflow: hidden;
  pointer-events: none;
}
.p-field--card-name .p-label--card, .p-field--card-name .p-label--withdraw, .p-field--card-name .p-label--neteller, .p-field--card-name .p-label--history, .p-field--card-name .p-label--bonus {
  opacity: 0;
}
.p-field.p-focus .p-label--card, .p-field.p-focus .p-label--withdraw, .p-field.p-focus .p-label--neteller, .p-field.p-focus .p-label--history, .p-field.p-focus .p-label--bonus {
  opacity: 0;
}
.p-field.p-not-empty .p-label--card, .p-field.p-not-empty .p-label--withdraw, .p-field.p-not-empty .p-label--neteller, .p-field.p-not-empty .p-label--history, .p-field.p-not-empty .p-label--bonus {
  opacity: 0;
}
.p-field.p-valid.p-dirty .p-label--card, .p-field.p-valid.p-dirty .p-label--withdraw, .p-field.p-valid.p-dirty .p-label--neteller, .p-field.p-valid.p-dirty .p-label--history, .p-field.p-valid.p-dirty .p-label--bonus {
  opacity: 0;
}
.p-field.p-invalid.p-dirty .p-label--card, .p-field.p-invalid.p-dirty .p-label--withdraw, .p-field.p-invalid.p-dirty .p-label--neteller, .p-field.p-invalid.p-dirty .p-label--history, .p-field.p-invalid.p-dirty .p-label--bonus {
  opacity: 0;
}
.p-field.p-invalid.p-dirty.p-empty .p-label--card, .p-field.p-invalid.p-dirty.p-empty .p-label--withdraw, .p-field.p-invalid.p-dirty.p-empty .p-label--neteller, .p-field.p-invalid.p-dirty.p-empty .p-label--history, .p-field.p-invalid.p-dirty.p-empty .p-label--bonus {
  opacity: 1;
}
.p-field.p-valid.p-dirty.p-focus .p-label--card, .p-field.p-valid.p-dirty.p-focus .p-label--withdraw, .p-field.p-valid.p-dirty.p-focus .p-label--neteller, .p-field.p-valid.p-dirty.p-focus .p-label--history, .p-field.p-valid.p-dirty.p-focus .p-label--bonus {
  opacity: 0;
}
.p-label--bonus {
  top: calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px));
}

.p-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100px;
  pointer-events: none;
  z-index: 5;
}
.p-loader::before {
  background: url("/img/icons/wallet-loader.svg");
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  width: 100px;
  height: 100px;
  opacity: 0;
}
.p-loader--small {
  min-height: var(--magic-icon-size, 24px);
}
.p-loader--small::before {
  background: url("/img/icons/wallet-loading.svg");
  margin: calc(var(--magic-icon-size, 24px) / 2 * -1) 0 0 calc(var(--magic-icon-size, 24px) / 2 * -1);
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
}
.p-loader--small-empty::before {
  background: url("/img/icons/wallet-loading-empty.svg");
}
.is-loading .p-loader::before {
  animation: loader-fade-animation 0.6s 0.2s linear 1 forwards;
}
.is-loading .p-loader--small::before {
  animation: loader-fade-animation 0.6s 0.2s linear 1 forwards, loader-spin-animation 0.6s 0.2s linear infinite forwards;
}
.is-loading .p-loader__text {
  animation: loader-fade-animation 0.6s 0.2s linear 1 forwards;
}
.p-loader__text {
  font-size: 0.75rem;
  position: absolute;
  top: 25%;
  left: 50%;
  width: var(--magic-wallet-mobile-size, 290px);
  height: var(--magic-space, 16px);
  text-align: center;
  color: var(--magic-text, #747474);
  opacity: 0;
  transform: translate3d(-50%, 25%, 0);
}
.p-loader__text::after {
  content: "...";
  display: inline-block;
  width: 10px;
  text-align: left;
  animation: ellipsis-animation 1.2s ease infinite forwards;
}
@keyframes loader-fade-animation {
  to {
    opacity: 1;
  }
}
@keyframes loader-spin-animation {
  to {
    transform: rotate(360deg);
  }
}
@keyframes ellipsis-animation {
  0% {
    content: "   ";
  }
  16.666% {
    content: ".  ";
  }
  33.333% {
    content: ".. ";
  }
  50% {
    content: "...";
  }
  66.666% {
    content: ".. ";
  }
  83.333% {
    content: ".  ";
  }
  100% {
    content: "   ";
  }
}

.p-merchant-info {
  font-size: 0.75rem;
  margin: calc(var(--magic-space, 16px) + var(--magic-space--small, 8px)) 0 var(--magic-space, 16px);
  max-width: var(--magic-wallet-mobile-size, 290px);
  text-align: center;
  color: var(--magic-text, #747474);
}

.p-message {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  margin-right: 0;
  padding: var(--magic-space--small, 8px);
  min-width: calc(100% + 22px);
  max-width: 18.75rem;
  min-height: 44px;
  box-shadow: 0 2px 1px rgba(var(--magic-dark, #000), 0.3), 0 -2px 1px -1px rgba(var(--magic-dark, #000), 0.15);
  opacity: 0;
  visibility: hidden;
  transform: translate3d(-50%, -100%, 0);
  transition: opacity var(--magic-duration, 0.3s), visibility 0s var(--magic-delay, 0.3s);
  will-change: opacity, visibility;
  z-index: 1;
}
.p-message.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--magic-duration, 0.3s), visibility 0s 0s;
}
.p-message__button {
  background: url("/img/icons/wallet-close.svg");
  position: absolute;
  top: var(--magic-space--x-small, 4px);
  right: var(--magic-space--x-small, 4px);
  width: var(--magic-space, 16px);
  height: var(--magic-space, 16px);
  z-index: 2;
}
.p-message__button:hover, .p-message__button:focus, .p-message__button:active {
  opacity: 0.65;
}
html[data-theme=dark] .p-message__button {
  background: url("/img/icons/wallet-close-dark.svg");
}
@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) .p-message__button {
    background: url("/img/icons/wallet-close-dark.svg");
  }
}
.p-message__icon {
  display: none;
}
.p-message__text {
  font-size: 0.81rem;
  display: inline-block;
  font-weight: bold;
  text-align: left;
  color: var(--magic-text, #747474);
  line-height: 1.3;
}
.p-message__text a {
  font-weight: var(--magic-font-weight-bold, 700);
  text-decoration: none;
  color: var(--magic-text-primary, #0c66ff);
}
.p-message__text a:hover {
  text-decoration: underline;
}
.p-message__title, .p-message__text {
  max-width: 100%;
}
.p-message-wrapper {
  position: relative;
}
.p-message--confirmation, .p-message--information {
  padding: var(--magic-space, 16px);
  min-width: 17.5rem;
  max-width: 18.75rem;
  text-align: center;
}
.p-message--card, .p-message--amount, .p-message--bonus, .p-message--withdraw, .p-message--withdraw-bank {
  background: var(--magic-background-light, #fff);
  top: auto;
  bottom: 7px;
  padding: calc(var(--magic-space, 16px) - var(--magic-space--x-small, 4px));
  border-radius: var(--magic-border-radius, 4px);
  min-width: calc(100% + var(--magic-space, 16px));
  box-shadow: 0 0 10px 0 var(--magic-message-dark-transparent, rgba(0, 0, 0, 0.125));
  transform: translate3d(-50%, 100%, 0);
}
.p-message--card::before, .p-message--card::after, .p-message--amount::before, .p-message--amount::after, .p-message--bonus::before, .p-message--bonus::after, .p-message--withdraw::before, .p-message--withdraw::after, .p-message--withdraw-bank::before, .p-message--withdraw-bank::after {
  content: none;
}
.p-message--card .p-message__title, .p-message--card .p-message__text, .p-message--card .p-message__decline, .p-message--amount .p-message__title, .p-message--amount .p-message__text, .p-message--amount .p-message__decline, .p-message--bonus .p-message__title, .p-message--bonus .p-message__text, .p-message--bonus .p-message__decline, .p-message--withdraw .p-message__title, .p-message--withdraw .p-message__text, .p-message--withdraw .p-message__decline, .p-message--withdraw-bank .p-message__title, .p-message--withdraw-bank .p-message__text, .p-message--withdraw-bank .p-message__decline {
  color: var(--magic-caption, #161616);
}
.p-message--card .p-message__title, .p-message--amount .p-message__title, .p-message--bonus .p-message__title, .p-message--withdraw .p-message__title, .p-message--withdraw-bank .p-message__title {
  font-size: 0.88rem;
  color: var(--magic-header, #161616);
}
.p-message--card .p-message__text, .p-message--amount .p-message__text, .p-message--bonus .p-message__text, .p-message--withdraw .p-message__text, .p-message--withdraw-bank .p-message__text {
  font-size: 0.75rem;
  text-align: center;
  color: var(--magic-text, #747474);
}
.p-message--card .p-message__decline, .p-message--amount .p-message__decline, .p-message--bonus .p-message__decline, .p-message--withdraw .p-message__decline, .p-message--withdraw-bank .p-message__decline {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight-bold, 700);
  margin: var(--magic-space--small, 8px);
  text-decoration: none;
  color: var(--magic-text-primary, #0c66ff);
  cursor: pointer;
}
.p-message--card .p-message__decline:hover, .p-message--amount .p-message__decline:hover, .p-message--bonus .p-message__decline:hover, .p-message--withdraw .p-message__decline:hover, .p-message--withdraw-bank .p-message__decline:hover {
  text-decoration: underline;
}
.p-message--card .p-message__confirm, .p-message--amount .p-message__confirm, .p-message--bonus .p-message__confirm, .p-message--withdraw .p-message__confirm, .p-message--withdraw-bank .p-message__confirm {
  margin: 0 var(--magic-space--small, 8px);
  width: auto;
  min-width: 100px;
}
.p-message--card {
  top: 0;
  bottom: auto;
  left: calc(-1 * var(--magic-space--small, 8px));
  transform: translate3d(0, calc(-100% + var(--magic-space, 16px)), 0);
}
.p-message--card .p-message__title {
  margin-right: auto;
}
.p-message--card .p-message__text {
  text-align: left;
}
.p-message--amount {
  top: 26px;
  bottom: auto;
  left: -9px;
  transform: translate3d(0, 0, 0);
}
.p-message--bonus {
  bottom: 56px;
  left: -9px;
  transform: translate3d(0, 0, 0);
}
.p-message--withdraw {
  bottom: 23px;
  left: -9px;
  transform: translate3d(0, 100%, 0);
}
.p-message--withdraw-bank {
  bottom: calc(-1 * var(--magic-space--small, 8px));
  left: calc(-1 * var(--magic-space--small, 8px));
  transform: translate3d(0, 100%, 0);
}
.p-message--right {
  right: 0;
  left: 0;
  min-width: 0;
  transform: translate3d(0, 0, 0);
}
.p-message--error, .p-message--success, .p-message--info {
  position: relative;
  margin-bottom: var(--magic-space--large, 32px);
}
.p-message--error .p-message__icon, .p-message--success .p-message__icon {
  display: block;
  margin: var(--magic-space, 16px) 0 0;
  padding: 0;
  width: calc(var(--magic-icon-size, 24px) * 2);
  height: calc(var(--magic-icon-size, 24px) * 2);
  pointer-events: none;
}
.p-message--error .p-message__icon--success, .p-message--success .p-message__icon--success {
  background: url("/img/icons/wallet-tick-solid.svg");
}
.p-message--error .p-message__icon--error, .p-message--success .p-message__icon--error {
  background: url("/img/icons/wallet-alert-solid.svg");
}
.p-message--success, .p-message--info {
  background: none;
}
.p-message--success .p-message__title, .p-message--info .p-message__title {
  display: none;
}
.p-message--success .p-message__text, .p-message--info .p-message__text {
  color: var(--magic-text, #747474);
}
.has-tooltip .p-message--info {
  background: var(--magic-light, #fff);
  flex: 1 1 100%;
  margin-top: var(--magic-space--small, 8px);
  margin-bottom: 0;
}
.has-tooltip .p-message--info .p-message__text {
  font-size: 0.75rem;
  text-align: center;
  color: var(--magic-dark, #000);
}
.has-tooltip .p-message--info .p-message__button {
  font-size: 0.75rem;
  display: block;
  margin: var(--magic-space--x-small, 4px)var(--magic-space--x-small, 4px)/2 auto 0;
  width: auto;
  position: static;
  border-radius: 0;
  height: auto;
  box-shadow: none;
  text-decoration: underline;
  border: 0;
  padding: 0;
}
.has-tooltip .p-message--info .p-message__button::before, .has-tooltip .p-message--info .p-message__button::after {
  content: none;
}
.has-tooltip .p-message--info .p-message__button:active {
  transform: none;
}
.has-tooltip .p-message--info.is-open {
  opacity: 0;
  visibility: hidden;
  transition: none;
  animation: open-message var(--magic-duration, 0.3s) calc(var(--magic-delay, 0.3s) / 2) 1 forwards;
  will-change: opacity, visibility;
}
@keyframes open-message {
  from {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
.p-message--info .p-message__icon {
  display: none;
}
.p-message--continue {
  cursor: default;
}
.has-tooltip .p-message--continue {
  background: var(--magic-message-medium-transparent, rgba(116, 116, 116, 0.125));
}
.has-tooltip .p-message--continue .p-message__button--continue {
  background: none;
  color: var(--magic-text-primary, #0c66ff);
  text-decoration: none;
}
.has-tooltip .p-message--continue .p-message__button--continue:hover, .has-tooltip .p-message--continue .p-message__button--continue:focus, .has-tooltip .p-message--continue .p-message__button--continue:active {
  background: none;
  text-decoration: underline;
  opacity: 1;
}
html[data-theme=dark] .has-tooltip .p-message--continue .p-message__button--continue {
  background: none;
}
@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) .has-tooltip .p-message--continue .p-message__button--continue {
    background: none;
  }
}
@media (min-width: 500px) {
  .p-message--bonus .p-message__confirm {
    width: auto;
  }
}

.visa-notification {
  position: relative;
  padding-left: var(--magic-space--large, 32px);
}
.visa-notification i {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
}

.p-method {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 0 var(--magic-space, 16px);
  width: 100%;
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  cursor: pointer;
}
.p-method::before {
  background: var(--magic-method-medium-transparent, rgba(116, 116, 116, 0.125));
  content: " ";
  position: absolute;
  top: calc((var(--magic-method-logo-large-height, 60px) - var(--magic-method-size, 50px)) / 2);
  left: 0;
  border-radius: var(--magic-border-radius, 4px);
  width: 100%;
  height: 100%;
  max-height: var(--magic-method-size, 50px);
  z-index: -1;
}
.p-method:last-child {
  margin: 0;
}
.p-method:hover, .p-method:focus {
  opacity: 0.85;
}
.p-method--changer {
  margin: 0;
  width: auto;
  cursor: default;
}
.p-method--changer::before {
  content: none;
}
.p-method--changer:hover, .p-method--changer:focus {
  opacity: 1;
}
.p-method--changer .p-method__logo {
  border-radius: var(--magic-border-radius, 4px);
  width: var(--magic-method-logo-standard-width, 80px);
  height: var(--magic-method-logo-standard-height, 32px);
  flex: 0 0 var(--magic-method-logo-standard-width, 80px);
}
.p-method--changer .p-method__logo::before {
  width: 42px;
  height: 24px;
}
.p-method--changer .p-method__header {
  margin: 0 0 0 var(--magic-space--small, 8px);
}
.p-method--changer .p-method__description {
  font-size: 0.69rem;
  font-weight: normal;
}
.p-method--changer .p-method__description--ellipsis {
  max-width: 48px;
}
.p-method__logo {
  background: var(--magic-background-light, #fff);
  flex: 0 0 var(--magic-method-logo-large-width, 80px);
  border-radius: 2px;
  width: var(--magic-method-logo-large-width, 80px);
  height: var(--magic-method-logo-large-height, 60px);
  box-shadow: 0 0 10px 0 var(--magic-method-dark-transparent, rgba(0, 0, 0, 0.125));
}
.p-method__logo::before {
  display: block;
  width: 62px;
  height: 34px;
  background-size: contain;
}
.p-method__header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1 0 auto;
  margin: 0 auto 0 var(--magic-space, 16px);
}
.p-method__title, .p-method__description {
  flex: 1 0 auto;
  line-height: 1;
}
.p-method__title {
  font-size: 0.75rem;
  margin: 0 0 var(--magic-space--x-small, 4px);
  text-transform: uppercase;
  color: var(--magic-header, #161616);
}
.p-method__description {
  font-size: 0.69rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  letter-spacing: -0.08px;
  color: var(--magic-text, #747474);
}
.p-method__description--ellipsis {
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.p-method__description--margin-top {
  margin-top: var(--magic-space--x-small, 4px);
}
.p-method__button {
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
  cursor: pointer;
}
.p-method__button:hover, .p-method__button:focus {
  opacity: 0.6;
}
.p-method__button--text {
  padding: var(--magic-space--x-small, 4px) var(--magic-space--small, 8px);
  color: var(--magic-light, #fff);
}
.p-method__button--icon {
  width: var(--magic-method-size, 50px);
  height: var(--magic-method-size, 50px);
}
.p-method__button--edit {
  background: url("/img/icons/wallet-edit.svg");
  background-size: 100% 100%;
  background-position: 50% 50%;
}
.p-method__button--change {
  font-size: 0.75rem;
  margin: 0 0 0 var(--magic-space--small, 8px);
  padding: var(--magic-space--small, 8px) var(--magic-space, 16px);
  border-radius: var(--magic-border-radius, 4px);
  height: var(--magic-space--large, 32px);
  background-color: transparent;
  box-shadow: inset 0 0 0 1px var(--magic-primary, #0c66ff);
  color: var(--magic-text-primary, #0c66ff);
  line-height: 1;
}

.p-method-changer {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 0 var(--magic-space--large, 32px);
  width: 100%;
  min-height: var(--magic-method-changer-size, 32px);
  color: var(--magic-text, #747474);
}
.p-method-changer--card {
  flex: 1 0 auto;
}

.p-methods__list {
  min-width: var(--magic-wallet-mobile-size, 290px);
}
.p-methods__message {
  background: var(--magic-background-light, #fff);
  margin: var(--magic-space--large, 32px) 0 var(--magic-space, 16px);
  padding: var(--magic-space, 16px);
}
.p-methods__spacer {
  margin: var(--magic-space--large, 32px) 0;
  border: none;
  border-bottom: 1px solid var(--magic-methods-dark-transparent, rgba(0, 0, 0, 0.125));
}

.c-progress {
  background: var(--magic-disabled, #e4e4e4);
  position: relative;
  margin: var(--magic-space--x-small, 4px) 0 0;
  width: 100%;
  height: var(--magic-space, 16px);
  overflow: hidden;
}
.c-progress--x-small {
  height: var(--magic-space--x-small, 4px);
}
.c-progress__bar {
  background: var(--magic-primary, #0c66ff);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  max-width: 100%;
  transition: width var(--magic-duration, 0.3s) ease;
  will-change: width;
}
.is-animated .c-progress__bar {
  animation: progress-animation var(--magic-duration, 0.3s) ease infinite;
}
@keyframes progress-animation {
  0% {
    width: 0;
  }
  100% {
    width: calc(100% - 2px);
  }
}

.p-radios--history {
  display: flex;
  margin-left: 0;
  justify-content: space-between;
}
.p-radios__item {
  cursor: pointer;
}
.p-radios__item--history {
  display: block;
  padding: 0;
  width: calc(50% - (8px - var(--magic-space--xx-small, 2px)));
}
.p-radios__label {
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 1;
}
.p-radios__label--history {
  font-size: 0.88rem;
  display: inline-block;
  flex: 0 0 auto;
  position: relative;
  margin: 0;
  padding: calc(var(--magic-space, 16px) - calc(var(--magic-space--x-small, 4px) / 2)) var(--magic-space--small, 8px);
  border: none;
  border-radius: var(--magic-radios-border-radius, 4px);
  outline: none;
  width: 100%;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px var(--magic-primary, #0c66ff);
  text-align: center;
  text-decoration: none;
  color: var(--magic-text-primary, #0c66ff);
  line-height: 20px;
  transform: scale(1);
  transition: transform calc(var(--magic-duration, 0.3s) / 2) ease, background-color var(--magic-duration, 0.3s) ease;
  will-change: transform, background-color;
  -webkit-appearance: none;
}
.p-radios__label--history:hover, .p-radios__label--history:focus {
  background-color: var(--magic-radios-primary-transparent, rgba(0, 234, 171, 0.125));
}
.p-radios__label--history:active {
  transform: scale(0.95);
}
.p-radios__input--history {
  position: absolute;
  left: -9999px;
}
.p-radios__input--history:checked ~ .p-radios__label--history {
  background-color: var(--magic-primary, #0c66ff);
  color: var(--magic-primary-contrast, #fff);
}

.p-round-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50% 0 0 -50%;
  width: 100%;
  height: 100%;
  color: var(--magic-primary, #0c66ff);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.p-round-progress__mask-left, .p-round-progress__mask-right, .p-round-progress__mask-filler, .p-round-progress__mask-spinner {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
}
.p-round-progress__mask-left, .p-round-progress__mask-right {
  width: 50%;
}
.p-round-progress__mask-left {
  left: 0;
}
.p-round-progress__mask-right {
  right: 0;
}
.p-round-progress__mask-filler, .p-round-progress__mask-spinner {
  width: 100%;
}
.p-round-progress__mask-filler {
  left: 0;
  transform-origin: 100% 50%;
  animation: rotation-animation 4s 4s linear 1 forwards;
}
.p-round-progress__mask-spinner {
  right: 0;
  transform-origin: 0 50%;
  animation: rotation-animation 4s 0s linear 1 forwards;
}
.p-round-progress__filler, .p-round-progress__spinner {
  position: absolute;
  top: 0;
  border: 3px solid currentColor;
  border-radius: 50%;
  width: 200%;
  height: 100%;
}
.p-round-progress__filler {
  left: 0;
}
.p-round-progress__spinner {
  right: 0;
}
.p-round-progress--static .p-round-progress__mask-filler, .p-round-progress--static .p-round-progress__mask-spinner {
  animation: none;
  transform: rotate(-180deg);
  transition: transform calc(var(--magic-duration, 0.3s) / 2) ease;
  will-change: transform;
}
.p-round-progress--static .p-round-progress__mask-filler.bonus-up {
  transition-delay: calc(var(--magic-delay, 0.3s) / 2);
}
.p-round-progress--static .p-round-progress__mask-filler.bonus-down {
  transition-delay: 0s;
}
.p-round-progress--static .p-round-progress__mask-spinner.bonus-up {
  transition-delay: 0s;
}
.p-round-progress--static .p-round-progress__mask-spinner.bonus-down {
  transition-delay: calc(var(--magic-delay, 0.3s) / 2);
}
@keyframes rotation-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.p-selects {
  display: block;
  position: relative;
  width: 100%;
}
@-moz-document url-prefix() {
  .p-selects .select {
    color: rgba(0, 0, 0, 0) !important;
    text-shadow: 0 0 0 var(--magic-caption, #161616);
  }
  .p-selects .select:-moz-focusring {
    text-shadow: 0 0 0 var(--magic-caption, #161616);
  }
}
.p-selects__select {
  background: var(--magic-background, #fbfbfb);
  font-size: 0.88rem;
  display: block;
  padding: var(--magic-selects-padding-vertical, 14px) var(--magic-selects-padding-horizontal, 16px);
  border: none;
  border-radius: var(--magic-selects-border-radius, 4px);
  width: 100%;
  height: var(--magic-selects-height, 44px);
  box-shadow: inset 0 0 0 1px var(--magic-selects-border, var(--magic-field-border, #b4b4b4));
  color: var(--magic-caption, #161616);
  line-height: var(--magic-selects-line-height, var(--magic-space, 16px));
  transition: box-shadow var(--magic-duration, 0.3s) ease;
  will-change: box-shadow;
  -webkit-appearance: none;
}
.p-selects__select:focus {
  box-shadow: inset 0 0 0 2px var(--magic-primary, #0c66ff);
  outline: none;
}
.p-selects__select:focus ~ .p-selects__icon {
  background: url("/img/icons/wallet-arrow-up.svg");
}
html[data-theme=dark] .p-selects__select:focus ~ .p-selects__icon {
  background: url("/img/icons/wallet-arrow-up-dark.svg");
}
@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) .p-selects__select:focus ~ .p-selects__icon {
    background: url("/img/icons/wallet-arrow-up-dark.svg");
  }
}
.p-selects__select:focus option {
  background: var(--magic-light, #fff);
  color: var(--magic-caption, #161616);
}
.p-selects__select::-ms-expand {
  display: none;
}
.p-selects__icon {
  background: url("/img/icons/wallet-arrow-down.svg");
  position: absolute;
  top: 50%;
  right: var(--magic-selects-padding-vertical, 14px);
  margin: calc((var(--magic-icon-size, 24px) / 2) * -1) calc(var(--magic-space--small, 8px) * -1) 0 0;
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
  pointer-events: none;
}
html[data-theme=dark] .p-selects__icon {
  background: url("/img/icons/wallet-arrow-down-dark.svg");
}
@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) .p-selects__icon {
    background: url("/img/icons/wallet-arrow-down-dark.svg");
  }
}

.p-subheader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 0 var(--magic-space--large, 32px);
  max-width: var(--magic-wallet-mobile-size, 290px);
  text-align: center;
}
.p-subheader:last-child {
  margin-bottom: 0;
}
.p-subheader--history {
  margin: calc(0 - var(--magic-space, 16px)) 0 var(--magic-space, 16px);
}
.p-subheader--history .p-subheader__text {
  margin: 0 0 var(--magic-space, 16px);
}
.p-subheader--history .p-subheader__link:last-child {
  margin: 0 0 var(--magic-space, 16px);
}
.p-subheader--withdraw {
  margin: calc(0 - var(--magic-space, 16px)) 0 0;
  flex-direction: column;
}
.p-subheader--bonus {
  margin: 0 0 var(--magic-space, 16px);
}
.p-subheader--bonus .p-subheader__text {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  color: var(--magic-text-primary, #0c66ff);
}
.p-subheader__text {
  font-size: 0.75rem;
  display: block;
  text-align: center;
  text-transform: none;
  color: var(--magic-text, #747474);
}
.p-subheader__text a {
  font-weight: var(--magic-font-weight-bold, 700);
  display: inline-block;
  position: relative;
  margin: 0 auto var(--magic-space, 16px);
  text-decoration: none;
  color: var(--magic-text-primary, #0c66ff);
}
.p-subheader__text a:hover {
  text-decoration: underline;
}
.p-subheader__text--bold {
  font-weight: var(--magic-font-weight-bold, 700);
}
.p-subheader__text--nowrap {
  white-space: nowrap;
}
.p-subheader__text--big {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight-bold, 700);
  color: var(--magic-caption, #161616);
}
.p-subheader__subheader-text a {
  font-weight: var(--magic-font-weight-bold, 700);
  text-decoration: none;
  color: var(--magic-text-primary, #0c66ff);
}
.p-subheader__subheader-text a:hover {
  text-decoration: underline;
}
.p-subheader__icon {
  display: block;
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
}
.p-subheader__icon--padlock {
  background: url("/img/icons/wallet-padlock.svg");
  margin-right: var(--magic-space--x-small, 4px);
}
.p-subheader__icon--remove {
  background: url("/img/icons/wallet-remove.svg");
  margin-right: var(--magic-space--x-small, 4px);
}
.p-subheader__icon--info {
  background: url("/img/icons/wallet-info.svg");
  margin-left: var(--magic-space--x-small, 4px);
}
html[data-theme=dark] .p-subheader__icon--info {
  background: url("/img/icons/wallet-info-dark.svg");
}
@media (prefers-color-scheme: dark) {
  html:not([data-theme=light]) .p-subheader__icon--info {
    background: url("/img/icons/wallet-info-dark.svg");
  }
}
.p-subheader__link {
  font-size: 0.88rem;
  cursor: pointer;
}
.p-subheader__link--show-more {
  font-weight: var(--magic-font-weight-bold, 700);
  position: relative;
  margin: 0 0 var(--magic-space, 16px);
  text-decoration: none;
  color: var(--magic-text-primary, #0c66ff);
}
.p-subheader__link--show-more:last-child {
  margin-bottom: 0;
}
.p-subheader__link--show-more:hover {
  text-decoration: underline;
}
.p-subheader__link--cancel-all {
  font-weight: var(--magic-font-weight-bold, 700);
  position: relative;
  margin: 0 0 var(--magic-space, 16px);
  text-decoration: none;
  color: var(--magic-text-error, #dd2727);
}
.p-subheader__link--cancel-all:last-child {
  margin-bottom: 0;
}
.p-subheader__link--cancel-all:hover {
  text-decoration: underline;
}
.p-subheader__message {
  display: inline-block;
  vertical-align: middle;
}
.has-bonus .p-subheader {
  margin-bottom: var(--magic-space, 16px);
}

.p-table {
  margin: 0 0 var(--magic-space, 16px) calc(var(--magic-space, 16px) * -1);
  border-spacing: 0.19rem;
  width: calc(100% + (var(--magic-space, 16px) * 2));
}
.p-table--balance {
  width: auto;
  margin: 0 auto var(--magic-space, 16px);
}
.p-table--bonus .p-table__header-row {
  background: rgba(var(--magic-light, #fff), 0.2);
}
.p-table--bonus .p-table__header-text {
  font-size: 0.88rem;
}
.p-table--bonus .p-table__body-row {
  background: none;
}
.p-table--bonus .p-table__body-cell {
  text-align: left;
}
.p-table--bonus .p-table__body-cell .p-table__body-text {
  font-weight: var(--magic-font-weight-semibold, 600);
  color: var(--magic-text, #747474);
}
.p-table--bonus .p-table__body-cell:nth-child(even) {
  text-align: right;
}
.p-table--bonus .p-table__body-cell:nth-child(even) .p-table__body-text {
  font-weight: var(--magic-font-weight-bold, 700);
  color: var(--magic-caption, #161616);
}
.p-table__header-cell {
  height: 1.88rem;
  vertical-align: middle;
}
.p-table__header-text {
  font-size: 0.69rem;
  font-weight: var(--magic-font-weight-bold, 700);
  white-space: nowrap;
  color: var(--magic-caption, #161616);
}
.p-table__body-cell {
  height: 1.88rem;
  padding: 0.38rem 0.5rem;
  text-align: center;
  vertical-align: middle;
}
.p-table__body-text {
  font-size: 0.69rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  color: var(--magic-text, #747474);
}
.p-table__body-text--left {
  font-size: 0.75rem;
  text-align: right;
  color: var(--magic-text, #747474);
}
.p-table__body-text--right {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight-bold, 700);
  text-align: left;
  color: var(--magic-caption, #161616);
}
.p-table__body-logo {
  background: var(--magic-background-light, #fff);
  border-radius: 0.25rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0.25rem;
  width: 2.25rem;
}
.p-table__body-icon {
  display: block;
  position: relative;
  width: var(--magic-icon-size, 24px);
  height: var(--magic-icon-size, 24px);
}
.p-table__body-icon--remove {
  background: url("/img/icons/wallet-remove.svg");
}
.is-loading .p-table__body-icon {
  opacity: 0;
  transition: opacity var(--magic-duration, 0.3s) ease;
  will-change: opacity;
}
.p-table__body-status {
  display: inline-block;
  position: relative;
  top: 2px;
  margin: 0 0 2px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.p-table__body-status--success {
  background: var(--magic-text-success, #00865a);
}
.p-table__body-status--error {
  background: var(--magic-text-error, #dd2727);
}
.p-table__body-status--rollback {
  background: none;
}
.p-table__body-status--rollback::before {
  width: 10px;
  height: 10px;
  clip: rect(0, 5px, 10px, 0);
}
.p-table--withdraw .p-table__body-row, .p-table--history .p-table__body-row {
  border-bottom: 1px solid var(--magic-table-medium-transparent, rgba(0, 0, 0, 0.125));
}
.p-table--withdraw .p-table__body-row:last-child, .p-table--history .p-table__body-row:last-child {
  border-bottom: 1px solid transparent;
}
.p-table--gaming .p-table__header-text {
  font-size: 0.63rem;
  display: inline-block;
  max-width: 120px;
  text-overflow: ellipsis;
  line-height: 1;
  overflow: hidden;
}
.p-table--gaming .p-table__header-text--game {
  max-width: 52px;
}
.p-table--gaming .p-table__header-cell {
  padding: 8px 2px;
}
.p-table--gaming .p-table__body-text {
  font-size: 0.63rem;
  display: inline-block;
  max-width: 120px;
  text-overflow: ellipsis;
  line-height: 1;
  overflow: hidden;
}
.p-table--gaming .p-table__body-text--game {
  max-width: 52px;
}
.p-table--gaming .p-table__body-cell {
  padding: 6px 4px;
}
@media (max-width: 320px) {
  .p-table {
    margin: 0 auto var(--magic-space, 16px);
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .p-table--gaming .p-table__header-text {
    font-size: 0.69rem;
  }
  .p-table--gaming .p-table__header-text--game {
    max-width: 82px;
  }
  .p-table--gaming .p-table__body-text {
    font-size: 0.69rem;
  }
  .p-table--gaming .p-table__body-text--game {
    max-width: 82px;
  }
}

.p-tabs {
  display: none;
}
.p-tabs.is-hidden {
  display: none;
}

.p-shop-info {
  text-transform: uppercase;
}
.p-shop-info__name {
  font-weight: var(--magic-font-weight-bold, 700);
  margin-bottom: 0;
}
.p-shop-info__program-title {
  margin-bottom: 0;
}
.p-shop-info__program {
  font-weight: var(--magic-font-weight-bold, 700);
}

.p-search-result {
  margin-bottom: var(--magic-space--large, 32px);
}

.p-shop {
  text-transform: uppercase;
  border-top: 1px solid var(--magic-medium, #747474);
  padding: var(--magic-space--x-small, 4px);
  cursor: pointer;
}
.p-shop__name {
  font-weight: var(--magic-font-weight-bold, 700);
  margin: 0;
}
.p-shop__details {
  margin: 0;
}

.p-input--shop {
  text-overflow: ellipsis;
}

.p-shopsmap {
  height: 400px;
  margin-bottom: var(--magic-space--small, 8px);
}
.p-shopsmap__hidden {
  display: none;
}

.p-method-removal {
  background: var(--magic-light, #fff);
  pointer-events: initial;
  z-index: 1;
  width: 290px;
  top: 100px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--magic-space--small, 8px) var(--magic-space--large, 32px);
  border-radius: var(--magic-border-radius, 4px);
  box-shadow: 0 0 10px 0 var(--magic-method-dark-transparent, rgba(0, 0, 0, 0.125));
}
.p-method-removal__title {
  font-size: 0.75rem;
  font-weight: var(--magic-font-weight-bold, 700);
}
.p-method-removal__description {
  font-size: 0.69rem;
  text-align: center;
}
.p-method-removal__actions {
  display: flex;
  justify-content: center;
}
.p-method-removal__confirm-button {
  margin-right: var(--magic-space--small, 8px);
}

.wallet--inner {
  max-width: 100%;
}
@media (min-width: 423px) and (max-width: 440px) {
  .wallet--inner {
    margin: 0 auto;
    max-width: 344px;
  }
}

@media (min-width: 768px) {
  .p-content {
    margin: 0 auto;
    max-width: 344px;
  }
}
.p-content-page {
  margin: 0;
}
.p-content__section, .p-content__footer {
  position: relative;
  min-width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .p-content__section, .p-content__footer {
    min-width: var(--magic-wallet-tablet-size, 344px);
    max-width: var(--magic-wallet-tablet-size, 344px);
  }
}
.p-content__section {
  margin-bottom: calc(var(--magic-space, 16px) - 4px);
}

.p-header {
  color: #231f20;
  margin: var(--magic-space, 16px) 0;
}

.p-subheader {
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .p-subheader {
    min-width: var(--magic-wallet-tablet-size, 344px);
    max-width: var(--magic-wallet-tablet-size, 344px);
  }
}
.p-subheader__text, .p-subheader__text a {
  font-size: 0.88rem;
}
.p-subheader__text {
  font-weight: var(--magic-font-weight-semibold, 600);
  padding: 0 var(--magic-space--small, 8px);
}
.p-subheader__text--big {
  font-weight: var(--magic-font-weight-bold, 700);
}
.p-subheader__text--bonus-empty {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight, 400);
  margin: var(--magic-space, 16px) auto;
}
.p-subheader__icon--remove {
  margin-right: var(--magic-space--small, 8px);
}
.p-subheader__icon--padlock {
  margin-right: 0;
}
.p-subheader--bonus {
  margin-bottom: var(--magic-space, 16px);
}

.p-methods__list {
  margin: var(--magic-space--large, 32px) auto;
}

.p-method::before {
  background: #efefef;
}
.p-method__title {
  text-transform: none;
}
.p-method__title::first-letter {
  text-transform: uppercase;
}
.p-method__description {
  font-size: 0.75rem;
}
.p-method--changer .p-method__logo {
  font-size: 0.75rem;
  border-radius: var(--magic-border-radius, 4px);
  height: var(--magic-logo-height, 56px);
}
.p-method__logo {
  height: var(--magic-logo-height, 56px);
  border-radius: var(--magic-border-radius, 4px);
}
.p-method__button--change {
  font-weight: var(--magic-font-weight-bold, 700);
  box-shadow: inset 0 0 0 1px var(--magic-dark, #000);
  padding: var(--magic-space--small, 8px) var(--magic-space-medium, 24px);
  margin-left: var(--magic-space-medium, 24px);
}
.p-method-removal {
  width: 100%;
  padding: var(--magic-space, 16px) var(--magic-space--small, 8px) var(--magic-space--small, 8px);
  align-items: start;
  top: 0;
}
@media (min-width: 423px) and (max-width: 440px) {
  .p-method-removal {
    margin: 0 auto;
    max-width: 344px;
  }
}
@media (min-width: 768px) {
  .p-method-removal {
    max-width: 344px;
  }
}
.p-method-removal__title {
  font-size: 0.88rem;
}
.p-method-removal__description {
  font-size: 0.75rem;
}
.p-method-removal__actions {
  width: 48%;
  justify-content: space-between;
}
.p-method-removal__cancel-button {
  margin-left: var(--magic-space, 16px);
  margin-bottom: 0;
  border: solid 1px var(--magic-dark, #000);
}

.p-table {
  --magic-text: #00eaab;
  --magic-text: #00eaab;
  min-width: 100%;
  max-width: 100%;
  margin: 0;
}
@media (min-width: 768px) {
  .p-table {
    min-width: var(--magic-wallet-tablet-size, 344px);
    max-width: var(--magic-wallet-tablet-size, 344px);
  }
}
.p-table__header-text {
  font-size: 0.75rem;
  color: var(--magic-tertiary, #141f33);
}
.p-table__body-cell {
  padding: 0 0 0 0;
  text-align: left;
  color: var(--magic-tertiary, #141f33);
}
.p-table__body-text {
  font-size: 0.75rem;
  font-weight: var(--magic-font-weight-semibold, 600);
  color: var(--magic-tertiary, #141f33);
}
.p-table__body-text--left {
  color: var(--magic-tertiary, #141f33);
}
.p-table__body-logo {
  margin: 0;
  padding: var(--magic-space--xx-small, 2px);
}
.p-table--bonus .p-table__body-cell {
  text-align: left;
}
.p-table--bonus .p-table__body-cell .p-table__body-text {
  font-weight: var(--magic-font-weight, 400);
  color: var(--magic-tertiary, #141f33);
}
.p-table--withdraw {
  text-align: left;
}

.p-button--primary {
  color: var(--magic-tertiary, #141f33);
  margin: 0;
}
.p-button--primary.is-inactive {
  color: rgba(35, 31, 32, 0.5);
}
.p-button--text {
  color: var(--magic-tertiary, #141f33);
}
.p-button--amount {
  font-size: 0.75rem;
  font-weight: var(--magic-font-weight-bold, 700);
  box-shadow: inset 0 0 0 1px var(--magic-dark, #000);
}
.p-button--amount:focus, .p-button--amount:active {
  background-color: var(--magic-primary, #0c66ff);
  color: var(--magic-dark, #000);
  box-shadow: inset 0 0 0 1px var(--magic-primary, #0c66ff);
}
.p-button--amount:first-child {
  margin: 0 2px 0 0;
}
.p-button--amount:last-child {
  margin: 0 0 0 var(--magic-space--xx-small, 2px);
}

.p-message {
  align-items: flex-start;
}
.p-message--bonus {
  bottom: 80px;
  left: 0;
  min-width: 100%;
}
.p-message__text {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight, 400);
  text-align: center;
  align-items: normal;
}
.p-message__title, .p-message--bonus {
  align-items: normal;
  text-align: justify;
}
.p-message--bonus, .p-message__confirm {
  margin: 0 !important;
  width: 100%;
}
.p-message__button {
  top: var(--magic-space--small, 8px);
  right: var(--magic-space--small, 8px);
  width: var(--magic-space-medium, 24px);
  height: var(--magic-space-medium, 24px);
}
.p-message--right {
  margin: 0 auto var(--magic-space, 16px);
  min-width: 100%;
  align-items: center !important;
}
.p-message--error .p-message__icon--success, .p-message--success .p-message__icon--success {
  background: url("/img/icons/wallet-tick-solid.svg");
}
.p-message--error .p-message__icon--error, .p-message--success .p-message__icon--error {
  background: url("/img/icons/wallet-error.svg");
}

.p-actions {
  display: flex;
  flex-direction: column;
  align-items: normal;
  width: 100%;
  margin: 0;
}
.p-actions--button {
  width: 100%;
  margin: 0;
}

.p-bonuses {
  margin-bottom: var(--magic-space, 16px);
}
.p-bonuses__list {
  margin-top: var(--magic-space-medium, 24px);
}

.p-bonus-page .p-content__footer:first-of-type {
  margin: 0;
}
.p-bonus__title {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight-bold, 700);
  text-transform: initial;
}
.p-bonus__header {
  height: var(--magic-bonus-height, 72px);
}
.p-bonus--changer {
  top: 8px;
}
.p-bonus__button, .p-bonus__toggle {
  top: calc(var(--magic-space, 16px) + 4px);
  right: var(--magic-space, 16px);
}
.p-bonus__text {
  font-size: 0.88rem;
  font-weight: var(--magic-font-weight, 400);
  text-align: justify;
}

.p-merchant-info {
  max-width: none;
  margin: 0;
}
@media (min-width: 768px) {
  .p-merchant-info {
    max-width: var(--magic-wallet-tablet-size, 344px);
  }
}

.p-form {
  margin-bottom: var(--magic-space, 16px);
}

.p-field--buttons {
  margin-bottom: var(--magic-space-medium, 24px);
}
.p-field__error--amount, .p-field__error--card, .p-field__error--bonus {
  font-weight: var(--magic-font-weight-semibold, 600);
}
.p-field__error--bonus {
  color: var(--magic-tertiary, #141f33);
}